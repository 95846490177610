import Dropzone from 'dropzone'
import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import {
  getMetaValue,
  removeElement,
  insertAfter,
} from './helpers'
import Compressor from 'compressorjs'

export default class extends Controller {
  static targets = ['input']

  connect () {
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
    Dropzone.autoDiscover = false // necessary quirk for Dropzone error in console

    const lockSubmitButton = () => {
      if (!this.submitButton) return

      this.submitButton.disabled = true
      this.inputTarget.dataset.locked = true
    }

    const unlockSubmitButton = () => {
      if (!this.submitButton) return

      if (!this.inputTarget.dataset.locked) return

      this.submitButton.disabled = false
      this.inputTarget.dataset.locked = false
    }

    this.dropZone.on('processing', lockSubmitButton)
    this.dropZone.on('canceled', unlockSubmitButton)
    this.dropZone.on('success', unlockSubmitButton)
    this.dropZone.on('error', unlockSubmitButton)
  }

  // Private
  hideFileInput () {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = 'none'
  }

  bindEvents () {
    this.dropZone.on('addedfile', (file) => {
      if (file.size > 10 * 1024 * 1024) {
        // eslint-disable-next-line no-new
        new Compressor(file, {
          quality: 0.80,
          maxHeight: 3840,
          maxWidth: 3840,
          success: (compressedFile) => {
            if (file.accepted) {
              // Create a new preview with the compressed image
              this.dropZone.createThumbnail(
                compressedFile,
                this.dropZone.options.thumbnailWidth,
                this.dropZone.options.thumbnailHeight,
                this.dropZone.options.thumbnailMethod,
                true, // Set to true to replace the original preview
                (dataURL) => {
                  this.dropZone.emit('thumbnail', file, dataURL)
                  createDirectUploadController(this, compressedFile).start()
                },
              )
            }
          },
          error: (e) => {
            console.error('Compression error:', e.message)
          },
        })
      } else {
        // No compression needed for smaller files
        setTimeout(() => {
          file.accepted && createDirectUploadController(this, file).start()
        }, 500)
      }
    })

    this.dropZone.on('removedfile', (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on('canceled', (file) => {
      file.controller && file.controller.xhr.abort()
    })
  }

  /** @type {HTMLInputElement} */
  get submitButton () {
    return this.inputTarget.form.querySelector("input[type='submit']")
  }

  get headers () {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') }
  }

  get url () {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }

  get maxFiles () {
    return this.data.get('maxFiles') || 1
  }

  get maxFileSize () {
    return this.data.get('maxFileSize') || 10
  }

  get acceptedFiles () {
    return this.data.get('acceptedFiles')
  }

  get addRemoveLinks () {
    return this.data.get('addRemoveLinks') || true
  }
}

class DirectUploadController {
  constructor (source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start () {
    this.file.controller = this
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput = this.createHiddenInput()
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  createHiddenInput () {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR (xhr) {
    this.emitDropzoneUploading()
  }

  emitDropzoneUploading () {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit('processing', this.file)
  }

  emitDropzoneError (error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit('error', this.file, error)
    this.source.dropZone.emit('complete', this.file)
  }

  emitDropzoneSuccess () {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit('success', this.file)
    this.source.dropZone.emit('complete', this.file)
  }
}

function createDirectUploadController (source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload (file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone (controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles || 1,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    thumbnailWidth: 600,
    thumbnailHeight: 300,
    thumbnailMethod: 'contain',
    dictRemoveFile: 'Ta bort',
    dictCancelUpload: 'Avbryt uppladdning',
    // previewsContainer: controller.element.querySelector('.dz-preview'),
    previewTemplate: controller.element.querySelector('.dz-preview-template')
      .innerHTML,
    dictDefaultMessage: 'Dra och släpp hit för att ladda upp',
  })
}
